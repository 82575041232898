import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { createTheme, ThemeProvider, StyledEngineProvider, adaptV4Theme } from "@mui/material/styles";
import theme from "./theme";
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { LoadingIndicatorContainer } from "./utils/loading";
import { AlertContainer } from "./components/Alert";

import PublicPage, { ResumePage } from "./pages/PublicPage";

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

ReactDOM.render(
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={createTheme(adaptV4Theme(theme))}>
            <LoadingIndicatorContainer>
                <Router>
                    <ScrollToTop />
                    <AlertContainer>
                        <Routes>
                            <Route path="*" element={<PublicPage />} />
                            {process.env.NODE_ENV != "production" ? <Route path="resume" element={<ResumePage />} /> : null}
                        </Routes>
                    </AlertContainer>
                </Router>
            </LoadingIndicatorContainer>
        </ThemeProvider>
    </StyledEngineProvider>,
    document.getElementById("root")
);
