import React, { useEffect, useState, useRef } from "react";

import { emphasize, useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import Hidden from "@mui/material/Hidden";
import Drawer from "@mui/material/Drawer";

import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import {
    PublicPage,
    PublicHeader,
    PublicPageContent,
    PublicFooter,
    Section,
    AnimateOnScroll,
    Title,
    Subtitle,
    Text,
    TypedText,
    Image,
} from "../components/Public";

import Chip from "@mui/material/Chip";
import { experience, education, certifications, otherProjects } from "./data";

import Icon from "@mui/material/Icon";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import CloudIcon from "@mui/icons-material/Cloud";
import HomeIcon from "@mui/icons-material/Home";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import LaunchIcon from "@mui/icons-material/Launch";
import DownloadIcon from "@mui/icons-material/Download";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import qrcode from "./qrcode.svg";

const createClasses = makeStyles((theme) => ({
    socialIcon: {
        padding: 0,
        "& svg": {
            fontSize: 40,
            padding: 0,
        },
    },
}));

const PublicContent = () => {
    const classes = createClasses();
    const theme = useTheme();
    const [menuOpen, setMenuOpen] = useState(false);

    const experienceRef = useRef(),
        educationRef = useRef(),
        projectsRef = useRef();

    const links = [
        {
            title: "About",
            onClick: () => {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
            },
        },
        {
            title: "Experience",
            ref: experienceRef,
        },
        {
            title: "Projects",
            ref: projectsRef,
        },
        {
            title: "Education",
            ref: educationRef,
        },
    ];

    const handleClick = (link) => () => {
        setMenuOpen(false);
        if (link.onClick) {
            link.onClick(link);
        } else if (link && link.ref && link.ref.current) {
            window.scrollTo({
                top: link.ref.current.offsetTop,
                behavior: "smooth",
            });
            //link.ref.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <PublicPage>
            <PublicHeader>
                <Stack spacing={4} direction="row" justifyContent="space-between" alignItems="center">
                    <Hidden mdUp>
                        <IconButton size="large" onClick={() => setMenuOpen(!menuOpen)} color="inherit">
                            <MenuIcon />
                        </IconButton>
                    </Hidden>
                    <Hidden mdDown>
                        <Stack spacing={2} direction="row" alignItems="center">
                            {links.map((l, key) => (
                                <Button key={key} sx={{ color: "#fff" }} onClick={handleClick(l)}>
                                    {l.title}
                                </Button>
                            ))}
                        </Stack>
                    </Hidden>

                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        href="/resume_jan_hrabal.pdf"
                        target="_blank"
                        rel="noreferrer"
                        endIcon={<DownloadIcon />}
                    >
                        Resume
                    </Button>
                </Stack>
            </PublicHeader>
            <PublicPageContent>
                <Section color="primary" variant="light">
                    <Box py={4}>
                        <div data-aos="fade-right">
                            <Subtitle left>Hello, my name is</Subtitle>
                            <Title gutterBottom>
                                <b>Jan Hrabal.</b>
                            </Title>
                            <Title>
                                I build things for
                                <br />
                                <TypedText
                                    strings={["backend", "frontend", "mobile", "integration"]}
                                    color={(theme.palette.tertiary && theme.palette.tertiary.main) || "#FFFFFF"}
                                />
                            </Title>
                            <div data-aos="fade-up">
                                <Text>
                                    I am a freelance full stack software engineer based in Prague, CZ, with strong passion for providing smart, robust and
                                    usable solutions for my clients.
                                </Text>
                                <Stack direction="row" spacing={2} alignItems="center" style={{ display: "inline-flex" }}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        size="large"
                                        onClick={() => {
                                            window.location.href = [..."moc.labarhnaj#ofni:otliam"].reverse().join("").replaceAll("#", "@");
                                        }}
                                    >
                                        Contact me
                                    </Button>
                                    <Button
                                        href="https://www.youtube.com/@jh683"
                                        target="_blank"
                                        rel="noreferrer"
                                        size="large"
                                        variant="contained"
                                        color="primary"
                                    >
                                        <YouTubeIcon />
                                    </Button>

                                    <Button
                                        href="https://www.linkedin.com/in/janhrabal/"
                                        target="_blank"
                                        rel="noreferrer"
                                        size="large"
                                        variant="contained"
                                        color="primary"
                                    >
                                        <LinkedInIcon />
                                    </Button>
                                </Stack>
                            </div>
                        </div>
                    </Box>
                    <div data-aos="fade-left">
                        <Image percentage={80} />
                    </div>
                </Section>
                <Section width="md" refProp={experienceRef}>
                    <div>
                        <Box my={2} sx={{ textAlign: "center" }}>
                            <div data-aos="fade-down">
                                <Subtitle>Experience</Subtitle>
                            </div>
                        </Box>
                        <Box my={2}>
                            <Items data={experience} />
                        </Box>
                    </div>
                </Section>
                <Section color="secondary" variant="light" width="md" refProp={projectsRef}>
                    <div>
                        <div data-aos="fade-down">
                            <Subtitle>Selected personal projects</Subtitle>
                        </div>
                        <Box my={2}>
                            <Items data={otherProjects} showPosition={false} />
                        </Box>
                    </div>
                </Section>
                <Section color="grey" variant="dark" width="md" refProp={educationRef}>
                    <div>
                        <div data-aos="fade-down">
                            <Subtitle>Education</Subtitle>
                        </div>
                        <Box my={2}>
                            <Items data={education} />
                            <Items data={certifications} />
                        </Box>
                    </div>
                </Section>
            </PublicPageContent>
            {/*<PublicFooter copyright copyrightSince="2002"></PublicFooter>*/}

            <Hidden mdUp>
                <Drawer anchor="left" open={menuOpen} onClose={() => setMenuOpen(false)}>
                    <List>
                        {links.map((l, key) => (
                            <ListItem key={key} disablePadding>
                                <ListItemButton onClick={handleClick(l)}>
                                    <ListItemText primary={l.title} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Drawer>
            </Hidden>
        </PublicPage>
    );
};

export default PublicContent;

const createItemClasses = makeStyles((theme) => ({
    root: {},
    item: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(8),
        display: "grid",
        gap: "2rem",
        gridTemplateColumns: `repeat(4, 1fr)`,
    },
    itemHeader: {
        gridColumn: "span 1/span 1",
        textAlign: "left",
    },
    itemContent: {
        gridColumn: "span 3/span 3",
        textAlign: "left",
    },
    [theme.breakpoints.down("md")]: {
        item: {
            gap: "0.5rem",
        },
        itemHeader: {
            gridColumn: "span 4/span 4",
        },
        itemContent: {
            gridColumn: "span 4/span 4",
        },
    },
    chips: {
        display: "flex",
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        flexWrap: "wrap",
        columnGap: theme.spacing(1),
        rowGap: theme.spacing(1),
    },
    link: {
        color: "inherit",
        display: "inline-flex",
        alignItems: "center",
        flexWrap: "wrap",
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline",
        },
    },
    linkIcon: {
        color: "inherit",
        paddingLeft: theme.spacing(0.5),
    },
}));

const Items = ({ data, animate = true, showPosition = true }) => {
    const classes = createItemClasses();

    return (
        <div className={classes.root}>
            {Array.isArray(data) && data.map((row, key) => <Item key={key} classes={classes} data={row} animate={animate} showPosition={showPosition} />)}
        </div>
    );
};

const Animated = ({ children, animate, aos }) => {
    if (!animate) {
        return <>{children}</>;
    }
    return <div data-aos={aos}>{children}</div>;
};

const dateValue = (data) => {
    let date = "";
    if (data.date) {
        date = data.date;
    } else {
        if (data.dateFrom) {
            date = data.dateFrom;
        }
        if (date && data.dateTo) {
            date += " - ";
        }
        if (data.dateTo) {
            date += data.dateTo;
        }
    }
    return date;
};

const Item = ({ data, classes, animate, showPosition }) => {
    let date = dateValue(data);
    let position = showPosition ? data.position : "";
    let companyElement = data.company;
    if (data.link) {
        companyElement = (
            <a href={data.link} target="_blank" className={classes.link}>
                <span>{data.company}</span>
                <LaunchIcon fontSize="small" className={classes.linkIcon} />
            </a>
        );
    }

    return (
        <div>
            <div>
                <div className={classes.item} data-aos={animate ? "fade-up" : undefined}>
                    <div className={classes.itemHeader}>
                        <Animated aos="fade-right" animate={animate}>
                            <Typography variant="subtitle1">{date}</Typography>
                        </Animated>
                    </div>
                    <div className={classes.itemContent}>
                        <div data-aos={animate ? "fade-left" : undefined}>
                            <Typography variant="subtitle1" gutterBottom>
                                {`${showPosition ? data.position + " · " : ""}`}
                                {companyElement}
                            </Typography>
                            <Typography variant="subtitle2" gutterBottom>
                                {data.place}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                {data.description}
                            </Typography>
                            {Array.isArray(data.tech) && (
                                <div className={classes.chips}>
                                    {data.tech.map((tech, key) => (
                                        <Chip key={key} label={tech} color="primary" size="small" />
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const stripePercentage = 36;

const createResumeClasses = makeStyles((theme) => ({
    root: {
        background: "rgb(204, 204, 204)",
        flex: "1",
        "& *": {
            boxSizing: "border-box",
        },
        "& > *": {
            opacity: "1 !important",
            transform: "translate(0) scale(1) !important",
        },
    },
    paper: {
        background: "white",
        display: "block",
        margin: "0 auto",
        marginTop: "0.5cm",
        marginBottom: "0.5cm",
        boxShadow: "0 0 0.5cm rgba(0, 0, 0, 0.5)",
        overflow: "hidden",
        "@media print": {
            margin: 0,
            boxShadow: 0,
        },
    },
    a4: {
        width: "21cm",
        height: "29.7cm",
    },
    padding: {
        padding: "0.5cm",
    },
    page: {
        display: "flex",
        height: "100%",
        //flexDirection: "row-reverse",
    },
    personalStripe: {
        flexBasis: `${stripePercentage}%`,
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        overflow: "hidden",
    },
    mainContent: {
        flexBasis: `${100 - stripePercentage}%`,
        overflow: "hidden",
    },

    personalStripeSection: {
        marginBottom: "1cm",
        "& h3": {
            marginBottom: "0.3cm",
        },
    },

    contactInfoRow: {
        display: "flex",
        alignItems: "center",
        columnGap: "0.2cm",
        marginBottom: "0.2cm",
    },

    skillRow: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        columnGap: "0.2cm",
        marginBottom: "0.1cm",
    },
    dataRow: {
        marginBottom: "0.5cm",
    },
    linkCodeWrapper: {
        textAlign: "center",
        position: "absolute",
        bottom: "0.5cm",
    },
    linkCode: {
        width: "3cm",
        height: "3cm",
    },
    experienceRow: {
        marginBottom: "0.7cm",
    },
    experienceRowGrid: {
        display: "grid",
        gap: "0.2cm",
        gridTemplateColumns: "1fr 3fr",
    },
    experienceTitle: { marginBottom: "0.1cm" },
    experienceRowDates: {},
    experienceRowContent: {},

    dateInfo: {
        fontSize: "0.27cm",
    },
    description: {
        fontSize: "0.3cm",
        marginBottom: "0.22cm",
    },
    tech: {
        //display: "flex",
        //gap: "0.15cm",
        //flexWrap: "wrap",
        fontSize: "0.3cm",
        color: theme.palette.secondary.dark,
        "&  div": {
            fontSize: "0.3cm",
            color: theme.palette.secondary.dark,
        },
    },
}));

export const ResumePage = () => {
    const classes = createResumeClasses();
    const [featuredProjects, otherProjects] = prepareData();

    let otherProjectsText = "";
    if (otherProjects && otherProjects.length) {
        otherProjectsText = otherProjects.map((data) => [dateValue(data), positionTitle(data)].join(" · ")).join(", ");
    }

    return (
        <div className={classes.root}>
            <div className={classnames(classes.paper, classes.a4)}>
                <div className={classes.page}>
                    <div className={classnames(classes.personalStripe, classes.padding)}>
                        <Typography variant="h4">
                            <b>Jan Hrabal</b>
                        </Typography>
                        <Typography>Full stack software engineer</Typography>
                        <div className={classes.personalStripeSection}>
                            <h3>Personal Info</h3>
                            <ContactInfoRow icon={<LinkedInIcon />} value="janhrabal" />
                            <ContactInfoRow icon={<AlternateEmailIcon />} value="info@janhrabal.com" />
                            <ContactInfoRow icon={<CloudIcon />} value="www.janhrabal.com" />
                            <ContactInfoRow icon={<LocalPhoneIcon />} value="+420 608 460 059" />
                        </div>
                        <div className={classes.personalStripeSection}>
                            <h3>Skills</h3>
                            <SkillRow name="Java" value={90} />
                            <SkillRow name="Spring" value={80} />
                            <SkillRow name="React" value={75} />
                            <SkillRow name="Javascript" value={70} />
                            <SkillRow name="Devops" value={70} />
                            <SkillRow name="Databases" value={60} />
                        </div>

                        <div className={classes.personalStripeSection}>
                            <h3>Education</h3>
                            {Array.isArray(education) &&
                                education.map((data, key) => (
                                    <div key={key} className={classes.dataRow}>
                                        <Typography variant="caption">{`${dateValue(data)} · ${data.position}`}</Typography>
                                        <Typography variant="body2">{data.company}</Typography>
                                        <Typography variant="body2">{data.description}</Typography>
                                    </div>
                                ))}
                        </div>

                        <div className={classes.personalStripeSection}>
                            <h3>Certifications</h3>
                            {Array.isArray(certifications) &&
                                certifications.map((data, key) => (
                                    <div key={key}>
                                        <Typography variant="caption">{`${dateValue(data)} · ${data.company}`}</Typography>
                                        <Typography variant="body2">{data.position}</Typography>
                                    </div>
                                ))}
                        </div>
                        <div className={classes.linkCodeWrapper}>
                            <img className={classes.linkCode} src={qrcode} />
                        </div>
                    </div>
                    <div className={classnames(classes.mainContent, classes.padding)}>
                        {Array.isArray(featuredProjects) &&
                            !!featuredProjects.length &&
                            featuredProjects.map((data, key) => {
                                return (
                                    <div key={key} className={classes.experienceRow}>
                                        <div className={classes.experienceTitle}>
                                            <Typography variant="body2">{positionTitle(data)}</Typography>
                                        </div>
                                        <div className={classes.experienceRowGrid}>
                                            <div className={classes.experienceRowDates}>
                                                {Array.isArray(data.dates) &&
                                                    data.dates.map((data, key) => (
                                                        <Typography
                                                            variant="caption"
                                                            component="div"
                                                            color={key ? "textSecondary" : "textPrimary"}
                                                            className={classes.dateInfo}
                                                        >{`${dateValue(data)}`}</Typography>
                                                    ))}
                                            </div>
                                            <div className={classes.experienceRowContent}>
                                                <Typography variant="body2" className={classes.description}>
                                                    {data.description}
                                                </Typography>

                                                {Array.isArray(data.tech) ? (
                                                    <div className={classes.tech}>
                                                        {/*(data.tech.map((t) => (
                                                            <div>{t}</div>
                                                        ))*/}
                                                        {data.tech.join(" · ")}
                                                    </div>
                                                ) : undefined}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        {!!Array.isArray(otherProjects) && otherProjects.length && (
                            <>
                                <div className={classes.experienceTitle}>
                                    <Typography variant="body2">Older positions</Typography>
                                </div>
                                {otherProjects.map((data, key) => (
                                    <div key={key}>
                                        <Typography variant="caption">
                                            {positionTitle(data)}
                                            {" · "}
                                        </Typography>
                                        <Typography variant="caption" className={classes.dateInfo}>
                                            {data.dates.map((date) => dateValue(date)).join(", ")}
                                        </Typography>
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const ContactInfoRow = ({ icon, value }) => {
    const classes = createResumeClasses();
    return (
        <div className={classes.contactInfoRow}>
            {icon || undefined}
            <Typography variant="body2">{value}</Typography>
        </div>
    );
};

const SkillRow = ({ name, value }) => {
    const classes = createResumeClasses();
    const icons = [];
    let score = 0,
        oldScore = 0;

    for (let i = 0; i < 5; i++) {
        score = (i + 1) * 20;

        if (value >= score) {
            icons.push(<StarIcon key={i} />);
        } else if (value > oldScore) {
            icons.push(<StarHalfIcon key={i} />);
        } else {
            icons.push(<StarBorderIcon key={i} />);
        }

        oldScore = score;
    }

    return (
        <div className={classes.skillRow}>
            <Typography variant="body1">{name}</Typography>
            <div>{icons}</div>
        </div>
    );
};

const positionTitle = (data) => {
    let title = [];
    if (data.position) {
        title.push(data.position);
    }
    if (data.company) {
        title.push(data.company);
    }
    if (data.city) {
        title.push(data.city);
    }

    return title.join(" · ");
};

const prepareData = () => {
    const maxItems = 6;

    const denseExpirience = [];
    const source = [...experience];
    let i = 0;

    while (i < source.length) {
        let exp = { ...source[i] };
        exp.dates = [{ dateFrom: exp.dateFrom, dateTo: exp.dateTo, date: exp.date }];
        exp.tech = Array.isArray(exp.tech) ? [...exp.tech] : [];
        exp.refs = [exp];
        i++;

        let j = i;
        while (j < source.length) {
            let dupl = source[j];
            if (dupl.company == exp.company && dupl.position == exp.position) {
                source.splice(j, 1);
                exp.dates.push({ dateFrom: dupl.dateFrom, dateTo: dupl.dateTo, date: dupl.date });
                exp.refs.push(dupl);

                if (Array.isArray(dupl.tech)) {
                    for (let t of dupl.tech) {
                        if (exp.tech.indexOf(t) < 0) {
                            exp.tech.push(t);
                        }
                    }
                }

                continue;
            }

            j++;
        }
        denseExpirience.push(exp);
    }

    const featuredProjects = denseExpirience.slice(0, Math.min(maxItems, denseExpirience.length));
    const otherProjects = [];
    for (let i = maxItems; i < denseExpirience.length; i++) {
        otherProjects.push(denseExpirience[i]);
    }

    return [featuredProjects, otherProjects];
};
