export const experience = [
    {
        dateFrom: "07/2020",
        dateTo: "02/2023",
        company: "DHL Information Services",
        place: "Prague",
        position: "Senior Software Engineer",
        description:
            "Development and support of customer data visibility tools, integration layer systems and warehouse management systems for DHL Supply Chain solutions",
        tech: [
            "Java",
            "J2EE",
            "Spring Boot",
            "Spring",
            "React",
            "React Native",
            "Javascript",
            "GraphQL",
            "REST API",
            "JMS",
            "Hibernate",
            "Oracle DB",
            "Elastic Search",
            "Docker",
            "Kubernetes",
            "OpenShift",
            "Azure Portal",
            "oAuth 2",
        ],
        /*    }, {
        dateFrom: "04/2020",
        dateTo: "now",
        company: "Your System a.s.",
        place: "Prague",
        position: "Java Developer",
        description: "Knowledge portal",
        tech: ["Spring Boot", "Java", "PostgreSQL", "REST API"]
    */
    },
    {
        dateFrom: "09/2019",
        dateTo: "06/2020",
        company: "Memsource",
        place: "Prague",
        position: "Groovy developer",
        description: "Implementation and support of Memsource's cloud-based translation platform for global enterprises and translation companies.",
        tech: ["Groovy", "vue.js", "Java", "MariaDB", "REST API"],
    },
    {
        dateFrom: "09/2014",
        dateTo: "07/2019",
        company: "DHL Information Services",
        place: "Prague",
        position: "Senior Software Engineer",
        description: "Development of customer data visibility tools, integration layer systems and warehouse management systems for DHL Supply Chain solutions",
        tech: ["Java", "J2EE", "Spring Boot", "Spring", "React", "React Native", "GraphQL", "REST API", "Hibernate", "Oracle DB", "Elastic Search"],
    },
    {
        dateFrom: "06/2014",
        dateTo: "09/2014",
        company: "Airbank a.s.",
        place: "Prague",
        position: "Senior Java Developer",
        description: "Implementation of back office tools and mortgage calculator for Airbank's internet banking",
        tech: ["Java", "Spring", "Wicket", "Hibernate"],
    },
    {
        dateFrom: "08/2013",
        dateTo: "05/2014",
        company: "DHL Information Services",
        place: "Prague",
        position: "Senior Software Engineer",
        description: "Development of customer data visibility tools, integration layer systems and warehouse management systems for DHL Supply Chain solutions",
        tech: ["Java", "J2EE", "Spring Boot", "Spring", "React", "React Native", "GraphQL", "REST API", "Hibernate", "Oracle DB", "Elastic Search"],
    },
    {
        dateFrom: "02/2013",
        dateTo: "07/2013",
        company: "Wirecard AG",
        place: "Munich",
        city: "Munich",
        position: "System Engineer",
        description: "Implementation and maintenance of payment systems and related components, for both online and offline payment processing.",
        tech: ["Java", "J2EE", "Spring", "Spring MVC", "Spring Batch", "Apache Camel", "Maven", "jUnit"],
    },
    {
        dateFrom: "06/2012",
        dateTo: "12/2012",
        company: "Equabank a. s.",
        place: "Prague",
        position: "Senior Java Developer",
        description:
            "Development of a new version of internet banking, including user interface (UX) and integration with other internal systems using enterprise service bus.",
        tech: [
            "ZK framework",
            "ZK Web Flow",
            "JSR-303 bean validator",
            "Spring",
            "Spring Security",
            "Spring WS",
            "JPA 2",
            "Maven",
            "jUnit",
            "Perforce",
            "Jenkins",
        ],
    },
    {
        dateFrom: "09/2010",
        dateTo: "06/2012",
        company: "Komerční Banka (Societe Generale Group)",
        place: "Prague",
        position: "Senior Java Developer",
        description: "Project DCS (Direct Strem Channels) is based mainly on maintenance and enhancements in internet banking and consecutive subsystems",
        tech: [
            "Wicket",
            "JSP",
            "Javascript",
            "XSLT/Apache FOP",
            "iText",
            "Spring",
            "MyBatis",
            "JDBC",
            "JMS",
            "IBM Websphere",
            "Maven",
            "Gradle",
            "SVN",
            "Jenkins CI",
        ],
    },
    {
        dateFrom: "03/2010",
        dateTo: "08/2010",
        company: "Škoda Auto a.s.",
        place: "Mladá Boleslav",
        position: "Senior Java Developer",
        description:
            "SA2 and Partner D-BASE projects cover Application Management & Support (AMS) processes within the company. As a part of B2B portal, these systems manage subventions for Škoda Auto, IVG and ŠAS dealers and handle complex information about importers.",
        tech: ["JSF", "Richfaces 3", "JSP", "XSLT", "JSR168 Portlets", "Java Mail API", "Oracle", "IBM Websphere"],
    },
    {
        dateFrom: "06/2009",
        dateTo: "03/2010",
        company: "Česká Spořitelna (ERSTE Group)",
        place: "Prague",
        position: "Senior Java Developer",
        description:
            "The main goal of project AMT 2.0 is an upgrade of Kondor++ into a robust independent solution and its transition into a flexible platform for the future development",
        tech: ["Java", "JSP", "XSLT/Apache FOP", "Oracle PL/SQL", "C++"],
    },
    {
        dateFrom: "08/2008",
        dateTo: "01/2009",
        company: "Česká Spořitelna (ERSTE Group)",
        place: "Prague",
        position: "Senior Java Developer",
        description:
            "Project Wealth Creation (WCR) developed and integrated new counselling tool into the teller system, offering semi-automatical suggestions of appropriate bank products to the financial advisors.",
        tech: ["J2EE", "Hibernate", "Apache Axis", "Oracle DB"],
    },
    {
        dateFrom: "03/2008",
        dateTo: "08/2008",
        company: "Česká Spořitelna (ERSTE Group)",
        place: "Prague",
        position: "Senior Java Developer",
        description:
            "CPS - Sporoservis system serves for providing consumer lending via bank branches, EPaK distribution network as well as an internet portal. The system covers all key processes in a field of creating and scoring loan applications as well as creating all mandatory documentation.",
        tech: ["JSF", "WebLogic WSRP", "J2EE", "Apache Axis"],
    },
    {
        dateFrom: "01/2008",
        dateTo: "03/2008",
        company: "Tatra Banka",
        place: "Prague, Bratislava",
        position: "Developer",
        description:
            "Creating tools for client data unification and merge among several banking systems. The solution was mainly set on DB layer (stored procedures) while the unification algorithms were implemented in Java.",
        tech: ["J2EE", "EJB3", "Oracle PL/SQL"],
    },
    {
        dateFrom: "08/2007",
        dateTo: "12/2007",
        company: "Česká Spořitelna (ERSTE Group)",
        place: "Prague",
        position: "Senior Java Developer",
        description:
            "The third phase of Kondor++ system solves functional requirements which enclose business processes such as reporting, umbrella funds, limits, mass order and integration with AMINA system.",
        tech: ["JSP", "JSTL", "jFreeChart", "Javascript", "J2EE", "JDBC", "C++", "Sybase T-SQL", "WebLogic", "Unix"],
    },
    {
        dateFrom: "02/2007",
        dateTo: "08/2007",
        company: "Škoda Auto a.s.",
        place: "Mladá Boleslav",
        position: "Software Architect",
        description:
            "Project KontaktWEB is a part of a B2B portal solution in Škoda Auto. It is used for managing customer data within the central database (CDB) and provides functions for supporting marketers and marketing studies.",
        tech: ["JSP", "JSR168 Portlets", "XML/XSLT", "J2EE", "Apache POI", "Java Mail API", "Web services", "IBM Websphere Portal Server"],
    },
    {
        dateFrom: "01/2007",
        dateTo: "02/2007",
        company: "Česká Spořitelna (ERSTE Group)",
        place: "Prague",
        position: "Java Developer",
        description:
            "PMWD is a non-visual component of the WebDON system. Its goal is to obtain and (or) compute complementary data for the front-end of the system.",
        tech: ["J2EE", "JSP", "Tapestry", "EJB 2", "Hibernate", "jUnit", "Apache Ant"],
    },
    {
        dateFrom: "09/2006",
        dateTo: "12/2006",
        company: "T-Mobile",
        place: "Prague",
        position: "Perl and DB Developer",
        description:
            "Implementation and customization of Request Tracker for T-Mobile. Request Tracker is a tool used for bug tracking, help desk ticketing, customer service, workflow processes, change management, etc.",
        tech: ["Perl", "PHP", "MySQL", "Oracle"],
    },
    {
        dateFrom: "11/2004",
        dateTo: "08/2006",
        company: "Česká Spořitelna (ERSTE Group)",
        place: "Prague",
        position: "Java Developer",
        description:
            "Complex solution for asset management focused mainly on generating cashflow from financial instruments, evaluating and benchmarking portfolios as well as advanced administration of instruments, deals reconciliation, mass orders, fee schemas etc.",
        tech: ["J2EE", "JSP", "JSTL", "JavaScript", "C++", "Weblogic", "Unix"],
    },
];

export const certifications = [
    {
        type: "achievement",
        date: "01/2011",
        company: "Oracle Certified Professional",
        position: "Java SE 6 Programmer certification",
        description: "Certification ID#: SUN685220",
        tech: [],
    },
];

export const education = [
    {
        type: "education",
        dateTo: "05/2011",
        dateFrom: "09/2008",
        company: "University of Economics in Prague",
        place: "The Faculty of Informatics and Statistics",
        position: "Masters degree",
        description: "Information systems and technology",
        tech: [],
    },
    {
        type: "education",
        dateTo: "06/2008",
        dateFrom: "09/2004",
        company: "Charles University in Prague",
        place: "The Faculty of Mathematics and Physics",
        position: "Bachelor degree",
        description: "Computer Science Program",
        tech: [],
    },
    /*{
        type: "achievement",
        date: "06/2003",
        company: "Czech Programming Championship",
        position: "National finals",
        place: "Janské Lázně",
        tech: ["Delphi"],
        links: [{ href: "http://sp.stv.cz/", label: "Web" }],
    },*/
];

export const otherProjects = [
    {
        dateFrom: "11/2022",
        position: "Author",
        company: "Inodio",
        link: "https://www.inodio.com/",
        description:
            "Originally just a simple time tracking for my friend's business, this project is evolving into one-stop suite for managing small businesses' processes. It includes modules for time tracking, project management (timesheets, expenses, invoices, billings), accounting transactions, taxes... ",
        tech: ["Spring Boot", "Java", "React", "javascript", "React Native", "expo", "Docker"],
    },
    {
        dateFrom: "03/2021",
        dateTo: "05/2021",
        position: "Author",
        company: "MyGroup",
        link: "https://www.joreport.cz/",
        description: "SaaS solution for managing status of project activities",
        tech: ["Spring Boot", "Java", "React", "javascript", "React Native", "Docker"],
    },
    {
        dateFrom: "2018",
        dateTo: "2019",
        position: "Author",
        company: "BTL Partner a media",
        description: "Ad hoc development of small mobile applications, self servicing kiosks and other solutions for events organized by the company",
        tech: ["React Native", "React", "Spring Boot", "Docker"],
    },
    {
        dateFrom: "01/2013",
        dateTo: "06/2015",
        position: "Author",
        company: "FlyMET Mobile Application",
        description:
            "Flymet is an Android application featuring weather forecast by providing graphical maps created by numerical weather prediction model called Flymet (see www.flymet.info for more information). The application allows its users to slide forth and back in time and display forecasts for 48 hours (in steps of 1 hour), to switch easily among maps, automatic data synchronization on the backgroud (over WiFi), etc.",
        tech: ["Android", "Java"],
    },
    {
        dateFrom: "07/2012",
        dateTo: "12/2012",
        position: "Author",
        company: "Pilot logbook",
        description:
            "Pilot-logbook is a web-based service for managing pilot's logbook accordingly to JAR-FCL 1 rules, providing many features over the paper logbook, such as creating reports, notifications, integration with social networks, synchronization with offline mobile application, etc. The project has been started for presentational purposes as well as for evaluating new technologies and components. ",
        tech: ["Spring", "JSF 2", "RichFaces 4", "JPA", "MySQL"],
    },
];
