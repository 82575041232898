import React from "react";
import { createContext, useContext, useRef, useState } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { makeStyles } from "@mui/styles";
import Hidden from "@mui/material/Hidden";

const noop = () => {
    console.warn("useLoadingIndicator hook used outside of LoadingIndicatorContainer");
};

const noContextObj = {
    startLoading: noop,
    stopLoading: noop,
    isLoading: false,
};

/*
 * hook
 */
export const useLoadingIndicator = () => {
    return useContext(LoadingIndicatorContext) || noContextObj;
};

const LoadingIndicatorContext = createContext(noContextObj);

export const LoadingIndicatorContainer = ({ children }) => {
    const loadingRef = useRef(0);
    const [loadingCnt, setLoadingCnt] = useState(0);

    const startLoading = () => {
        loadingRef.current += 1;
        setLoadingCnt(loadingRef.current);
    };

    const stopLoading = () => {
        let cnt = loadingRef.current - 1;
        if (cnt < 0) {
            cnt = 0;
        }
        loadingRef.current = cnt;
        setLoadingCnt(loadingRef.current);
    };

    return <LoadingIndicatorContext.Provider value={{ startLoading, stopLoading, isLoading: loadingCnt > 0 }}>{children}</LoadingIndicatorContext.Provider>;
};

const createLoaderClasses = makeStyles(() => ({
    root: {
        position: "relative",
        width: "100%",
        zIndex: 2,
    },
    indicator: {
        position: "absolute",
        top: 0,
        width: "100%",
    },
}));

export const LoadingIndicator = ({ color, hidden }) => {
    const classes = createLoaderClasses();
    const { isLoading } = useLoadingIndicator();

    if (!isLoading) {
        return null;
    }

    if (!!hidden) {
        return (
            <Hidden {...{ [hidden]: true }}>
                <div className={classes.root}>
                    <LinearProgress color={color || "secondary"} className={classes.indicator} />
                </div>{" "}
            </Hidden>
        );
    }

    return (
        <div className={classes.root}>
            <LinearProgress color={color || "secondary"} className={classes.indicator} />
        </div>
    );
};
